.courses{
   padding: 4rem  8rem;
 }


 @media(max-width : 912px) {
  .courses {
    padding: 4rem 4rem;
  }
 }

 @media(max-width : 540px){
  .courses {
    padding: 4rem 4rem;
}

}


@media(max-width : 380px){

  .courses {
    padding: 4rem 2rem;
  }

}
