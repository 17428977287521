
.dashboard{
   /* min-height: 100vh; */
   padding: 4rem  8rem;
 }

 .dashboard .welcome_box h3{
   font-size: 2rem;
 }

 .dashboard .welcome_box .welcome_header{
   display: flex;
   justify-content: space-between;
   margin-top: 1rem;
 }

 .dashboard_stats{
   width: 100%;
   background: var(--color-blue-900);
   min-height: 20vh;
   margin-top: 3rem;
   position: relative;
   border-radius: 1rem;
   display: flex;
   justify-content: space-between;
 }
 .dashboard_mask{
   position: absolute;
   padding: 2rem;
   left: 0;
   top: 0;
   bottom: 0;
 }

 .dashboard_stat_img img{
   position:absolute;
   left: 13rem;
   top: 0;
   width: 15vw;
 }

 .dashboard .stats{
   color: var(--bg-color);
   display: flex;
   align-items: center;
   margin-right: 10rem;
 
 }

 .dashboard .stats h3{
   font-size: 3rem;
   margin-bottom: 1rem;
 }

 .dashboard .stats .stat{
   margin-right: 3rem;
 }

 .dashboard_header_text{
   margin-top: 3rem;
   font-size: 2rem;
 }

 .view_courses{
   display: flex;
   justify-content: flex-end;
 }

 .view_courses a{
   color: var(--primary-color);
 }

 .video_component{
   display: flex;
   gap: 5rem;
   flex-wrap: wrap;
   /* justify-content: space-between; */
   margin-top: 2rem;
 }
 .leaderboard_dash{
   margin-top: 10rem;
 }

 .leaderboard_dash h3{
   font-size: 2.5rem;
 }

 .leaderboard_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
 }

 .leaderboard_box{
  margin-top: 3rem;
 }

 .leaderboard_buttons{
   background: var(--color-red-100 );
   padding: .7rem;
   border-radius: 1rem;
 }

 .leaderboard_buttons  button:nth-child(1){
    margin-right: .5rem;
    /* background: var(--color-red-400); */
    border-radius: 1rem;
    
 }

 .leaderboard_buttons  button:nth-child(2){
  margin-right: 1rem;
  /* background: var(--color-red-500); */
  border-radius: 1rem;
  /* color: var(--bg-color); */
  
}

.view_leaderboard_button a{
  color : var(--color-red-500);
}

.leaderboard_container{
  display: flex;
  justify-content: space-between;
  padding: 2rem 5rem;
  background: var(--color-red-100);
  align-items: center;
  margin-bottom: 3rem;
}
.leaderboard_profile{
    display: flex;
    justify-content: center;
    align-items: center;
}
.leaderboard_profile p{
  font-weight: 600;
}
.leadboard_details img {
   width: 2vw;
   margin-right: 2rem;
}

.leaderboard_points h3{
  color : var(--color-yellow-100);
}

.leaderboard_buttons .active{
  background: var(--primary-color);
  color: #fff;
}



@media(max-width : 768px){

  /* .dashboard_stat_img img {
    left: 8rem;
    top: 37px;
    width: 18vw;
  } */
  .dashboard_stat_img img {
    left: 8rem;
    top: -8px;
    width: 15vw;
}
  .dashboard .stats h3 {
    font-size: 2rem;
 
}
.dashboard {
  padding: 4rem 4rem;
}


}
@media(max-width : 690px){

  
  .video_component {
    display: block ;
  }

  

}

@media(max-width : 674px){

  .dashboard .stats {
    margin-right: 1rem;
  }
 

  

}
@media(max-width : 600px){
  .dashboard {
  
    padding: 4rem  3rem;
}
}

@media(max-width : 540px){
 

  .dashboard {
    padding: 4rem  4rem;
  }
  .dashboard_stat_img img {
    left: 2rem;
    top: 51px;

}
.dashboard_header_text {
  font-size: 1.7rem;
}


}

@media(max-width : 414px){
  .dashboard {
    padding: 4rem 1rem;
}
.footer .newsletter {
  width: 100%;
}
.leadboard_details img {
  width: 6vw;
}
.leaderboard_profile p {
  font-size: 1.3rem;
}
}


@media(max-width : 380px){

  .dashboard {
    padding: 4rem 2rem;
  }

 .leaderboard_buttons button {
    padding: 4px 10px;
    font-size: 13px;
    cursor: pointer;
}
.leaderboard_dash h3 {
  font-size: 1.5rem;
}
.leaderboard_points{
  text-align: center;
}
.leadboard_details img{
  display: none;
}
.leaderboard_profile p {
  font-size: 1.2rem;
}
.dashboard_header_text {
  font-size: 1.2rem;
}
}










