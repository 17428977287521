.loader {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh; /* Full screen height for loader */
   font-size: 1.5rem;
   color: #4caf50; /* Color for loader text */
 }
 
 .spinner {
   border: 5px solid #f3f3f3; /* Light grey */
   border-top: 5px solid #4caf50; /* Green */
   border-radius: 50%;
   width: 40px;
   height: 40px;
   animation: spin 1s linear infinite;
   margin-right: 10px;
 }
 
 @keyframes spin {
   0% { transform: rotate(0deg); }
   100% { transform: rotate(360deg); }
 }
 