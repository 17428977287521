.footer_background_img {
   background-image: url('../../assets/footer_img.png');
   background-size: 'cover';
   background-position: 'center';
   background-repeat: 'no-repeat';
   width: 100%; 
   height: 20rem;
   padding : 10rem;
 }


 @media(max-width : 768px){

   .footer_background_img {
      background-image: none;
      height:0;
      padding : 0;
     
    }

 

 }

