/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Inter:wght@100..900&display=swap');

*{
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   text-decoration: none;
   border: none;
   outline: none;
   scroll-behavior: smooth;
   font-family: 'Lora', 'inter';
}

:root{

   --bg-color : #FFFFFF;
   --primary-color :  #77121A;
   --color-red-300 : #FF0000;
   --color-red-100 : #FDEBEB;
   --color-red-200 :  #FAB0B0;
   --color-red-400 : #dca5a5;
   --color-red-500 : #AE5B5B;
   --color-red-20 : #FFECEC;
   --color-red-150 : #FFDFE2;

   /* border: 1px solid #5F1016 */
  
   --color-grey-100 : #D9D9D9;
   --color-grey-150: #F9FAFB;
   --color-grey-200 : #E9EFF2;
   --color-grey-400 : #B4B4B4;
   --color-black : #000;
   --color-black-900 : #0C1821;
   --color-blacl-100: #395C77;
   /* background: #1B3A50; */
   --color-blue-900 : #0C1821;

   --color-green-100 :  #24B200;

   --color-green-50 : #F0FFEC;


   --color-yellow-100 : #FFBB12;




}

html{
   font-size: 62.5%;
   overflow-x: hidden;
}

a {
  text-decoration: none; 
  color: inherit;        
}


a:hover, 
a:active, 
a:focus, 
a:visited {
  color: inherit;        
  text-decoration: none; 
}


body {
   background-color: var(--bg-color);
   color: var(--text-color);
   height: 100%;
}

.login-header .logo{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 2rem 2.9rem;
    /* text-align: center; */
}
.login-header .logo img {
  max-width: 10vw;
}
.login-header .logo span{
  color: var(--color-grey-100);
}

.login_box{
  height: calc(100vh - 15vh);
}
.login_box{
  display: flex;
}
.login_box .login_img{
  min-width: 50vw;
  margin-left: 2rem;
  border-radius: 5rem;
}
.login_box  .login_section {
 min-width: 50vw;
 display: flex;
 justify-content: center;
 margin-top: 20rem;
}

#sidebar-menu{
  font-size: 3rem;
  display:none;
}
.login_container .login_header {
  text-align: center;
}
.login_container .login_header h3{
  font-weight: 500;
  font-size: 2.6rem;
}
.login_container .login_header span{
   color : var(--color-grey-100);
   font-size : 1.2rem;
   align-items: center;
}
.form_box {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}
.form_box input{
  border: 1px solid var(--color-grey-100);
  padding: .9rem;
  width: 30vw;
}

.form_box label{
  font-size: 1.5rem;
  margin-bottom: .9rem;
}

.remember_me{

 align-items: center;
  /* display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse; */
}
.remember_me label{
  margin-left: 1rem;
}
.btn_box {
  margin-top: 2rem;
}
.btn_box button{
  padding: 1.5rem;
  width: 100%;
  background: var(--primary-color);
  color: var(--bg-color);
  border-left: .8rem solid var(--color-red-300);
  border-right: .8rem solid var(--color-red-300);
}
.forgot_password_box{
  margin-top: 2rem;
}

.forgot_password_box a{
  color: var(--color-red-300);
  
}
.forgot_password_box a:hover{
  cursor: pointer;
}

.back_to_login_box{
  margin-top: 2rem;
  text-align: center;
  color: var(--color-grey-400);
  font-size: 1.4rem;
}

.back_to_login_box a {
  color: var(--color-grey-400);
}
.header_img{
  text-align: center;
}

.back_to_login_box p a{
  font-weight: 700;
  color: var(--color-black);
}
.back_to_login_box p{
  margin-bottom: 1rem;
}
.mail_reset{
  font-weight: 600;
  color: var(--color-black);
}

.header_img img{
  width: 4vw;
}
.login_header img{
  width: 1.5vw;
}
#login_img_mobile{
  display:none;
}

.error-card {
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.success-card {
  background-color: #d4edda;
  color: #155724;
  padding: 15px;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.navbar{
  background: var(--color-grey-150);
  position: relative;
  width: 100%;
  top: 0;
  right:0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 6rem;

}
.navbar .nav_logo {
  display: flex;
  flex-direction: column;
}
.navbar .nav_logo img{
  width: 10vw;
}

.navbar .nav_logo span{
  color: var(--color-grey-400);
}

.navbar .menu a{
   font-size: 1.3rem;
   margin-right: 1.4rem;
   padding: 1rem;
}

.navbar .menu .active{
  background:var(--primary-color);
  color:#fff;
   /* var(--color-red-100 ); */
  border: 1px solid var(--primary-color);
  border-radius: .8rem;
}
.notification_box{
  position: relative;
}
.notification_box .logoutBox{
  background:var(--color-red-100 ); 
  position: absolute;
  right: -4rem;
  top: 5rem;

  padding: 2rem;
  width: 12vw;
  height: 15vh;
  text-align: center;
  cursor: pointer;
  display: flex;;
  flex-direction: column;

}
/* .notification_box .logout{
  position: absolute;
  right: -4rem;
  top: 5rem;
  background: var(--color-red-100 );
  padding: 1rem;
  width: 9vw;
  text-align: center;
  cursor: pointer;
 
} */
.notification_box .logoutBox a{
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}
.notification_box .logoutBox a:hover{

  background:var(--color-grey-150);
 

 
}

.notification_box a{
  color : var( --primary-color);
}

.notification_box .notification{
  background: var(--color-grey-200);
  padding: 1rem;
  border-radius: .9rem;
  position: relative;
}

.notification_box .notification i{ 
  font-size: 1.4rem;
 }

 .notification_box .notification .notification_count{ 
    position: absolute;
    top: .8px;
    left: 19px;
    background: var(--color-red-300);
    padding: 3px;
    color: var(--bg-color);
    width: 10px;
    height: 10px;
    border-radius: 50%;
 }

 .notification_box .notification .notification_text{
  position: absolute;
  top: .7px;
  font-size: .6rem;
  }

  .notification_box span:nth-child(1){
    margin-right: 2.5rem;
  }

  .initials{
    background: var(--color-red-100);
    padding: 1rem;
    border-radius: 50%;
    color: var(--primary-color);
  }
  .settingsIcon{
    display: none;
  }

  .footer{
    background: var(--color-black-900);
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    height: calc(100vh - 30vh);
    color: #fff;
    position: relative;
   
    padding:10rem 0 0 0 ;
 
  }

  .footer .footer_menu{
    display: flex;
    justify-content: space-around;
    /* margin-top: 10rem; */
   
  }
  

  .footer .footer_menu .socials_box {
       width: 20vw;
  }
  .footer .footer_menu .socials_box p{
    line-height: 1.5rem;
    text-align: justify;
    margin-top: 1rem;
    
  }
  .footer .footer_menu .socials_box  .nav_logo img {
    width: 14vw;
  }

  .footer .menus{
    display: flex;
   
  }
  .footer .menus .link{
    display: flex;
    flex-direction: column;
  }

  .footer .menu{
    margin-right: 4rem;
  }
  .footer .footer_menu .social_icons {
    margin-top: 1rem;
  }
  .footer .footer_menu .social_icons i{
      font-size: 1.8rem;
      margin-right: 1rem;
  }

  .footer .menu h3{
    margin-bottom: 2rem;
    color: var(--color-blacl-100);
  }
  .footer .newsletter h3{
    margin-bottom: 2rem;
    color: var(--color-blacl-100);
  }

  .footer .menu  p , .footer .menu  a{
    margin-bottom: 1rem;
  }

  .footer .newsletter{
    width: 20vw;
  }
  .footer .newsletter_form {
    margin-top: 1rem;
  }

  .footer .newsletter_form input ,  .footer .newsletter_form button{
    padding: .5rem;
  }
  .footer .newsletter_form button{
    background: var(--primary-color);
    color: var(--bg-color);
    border: 1px solid var(--primary-color);
  }
  .footer_img{
    margin-top: 10rem;
  }
  .copyright_box{
    display: flex;
    position: absolute;
    justify-content: space-between;
    bottom: 0;
    border-top: 1px solid var(--color-blacl-100);
    width: 100%;
    padding: 2.5rem;
  }
  .faqs a{
    margin-right: 1rem;
    text-decoration: underline;
  }
 

  /* BREAKING POINTS */
  @media(max-width : 1200px){

    html{
      font-size: 55%;
    }

  }

  @media(max-width : 991px) and (orientation: portrait){

    .courses {
      padding: 4rem 4rem;
  }

  }

  @media(max-width : 768px)  and (orientation: portrait){

    .login-header .logo img {
      max-width: 35vw;
  }
 

    .login_box {
        flex-direction: column-reverse;
        justify-content: space-between;
        margin-top: 10rem;
      }
      .login_box  .login_section {
        display: block;
        margin: 2rem 4rem; 
    }
    .form_box input {
      width : 100%;
    }

    #sidebar-menu{
      display:block;
    }
    .settingsIcon{
      display: block;
    }
    .header_menu{
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding: 1rem 3%;
      background: var(--color-grey-200);
      display: none;
      z-index: 2000;
    }
    .header_menu.active{
      display:block;
   }
    .header_menu a {
      display: block;
      font-size: 2rem;
      margin: 2rem 0;
    }
    .notification_box{
      display: none;
    }

    footer .footer_menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 3rem;
    
    }
    .footer .footer_menu .socials_box p{
      margin: 2rem 0;

    }

    .footer .footer_menu .socials_box {
      margin-bottom: 3rem;
   }

  footer .menus {
    justify-content: space-between;
   }

   .footer .newsletter {
    width: 40vw;
   }

   .newsletter_form button {
    padding: 1.2rem;
}



  }

  @media(max-width : 690px) {

   
     /* #login_img_mobile{
      display:block;
      
    }
    #login_img{
      display: none;
    } */

    .dashboard_stat_img img {
      left: 3rem;
      top: 41px;
     
  }
  .footer .footer_menu .socials_box {
    width: 40vw;
}
.video_component {
  gap: 7rem
}

.video-player video {
  width: 100%;
}
.video-player {
width: 100%;
}

.footer {
 
  height: 100%;
 
}
.copyright_box {
 
   position: relative;
}

  

  }

  @media(max-width : 610px){

    .dashboard .stats {
      margin-right: 2rem;
    }

    .navbar .nav_logo img {
      width: 21vw;
  }
  .footer .newsletter {
    width: 50vw;
}

.navbar {
  padding: 1.6rem 4rem;
}
  

  }


 


  @media(max-width : 485px){

    .navbar {
  
      padding: 1.6rem 3rem;
  }

    .footer .menus {
      flex-direction: column;
  }
  .footer {
    height: auto;
  }
  .footer .menu {
   
    margin-bottom: 2rem;
}

.footer .newsletter {

  margin-bottom: 9rem;
}

  }




  @media(max-width : 380px){
 
    .dashboard_stat_img img {
      display: none;
    }

    .navbar {

      padding: 1.6rem 2rem;
  }
  .footer .newsletter {
    width: 100%;
}
.navbar .nav_logo img {
  width: 23vw;
}

  }


  @media(max-width:350px){
    .copyright_box {
      display: flex;
      flex-direction: column;
      align-items: center;
     
  }
  .copyright_box p{
    margin-bottom: 1rem;
  }
  .video-player{
    margin-bottom: 2rem;
  }
  .navbar .nav_logo img {
    width: 29vw;
}
.footer .footer_menu .socials_box {
  width: 100%;
}
.footer .footer_menu .socials_box  .nav_logo img {
  width: 35vw;
}

  }



  







 





  
  
