.quiz_header{
   background:#140B0B;
   width: 100%;
   min-height: 20vh;
   color: #fff;
   padding: 4rem 15rem;
   
}

.quiz_title {
   margin-top: 6rem;
}
.quiz_title h1{
   margin-bottom: 1rem;
}
.quiz_mins{
   margin-top: 2rem;
}
.quiz{
   padding: 4rem 15rem;
}


.questions{
   border: 1px solid #eee;
   padding: 4rem;
   border-radius: 2rem;
   margin-bottom: 3rem;
}
.questions h2{
   font-size: 3rem;
}
.questions h4{
   margin-top: 1rem;
   font-size: 1.8rem;
   font-weight: 400;
   margin-bottom: 1rem;
}
.questions  span{
   color: var(--color-grey-400);
}
.answers {
   margin-top: 1rem;

   align-items: center;
  

}
.answers ul{
   list-style: none;
}
.answers ul li{
   margin-bottom: 1rem;
   padding: 1.5rem;
}
.answers ul li input{
   margin-right: 1rem;
}


/* Styles for correct and incorrect answers */
.correct {
   background-color: #d4edda; 
   color: #155724; 
   border: 1px solid #c3e6cb;
   /* padding: 5px; */
   border-radius: 4px;
}

.incorrect {
   background-color: #f8d7da;
   color: #721c24; 
   border: 1px solid #f5c6cb; 
   /* padding: 5px; */
   border-radius: 4px;
}


.answers ul li {
   list-style: none;
   margin-bottom: 10px;
}

.result_box{
   

   border-radius: 2rem;
   padding: 2rem 4rem;
   margin: 4rem 30rem;
   min-height: 20vh;
}
.result_box.green{
   background: var(--color-green-50);
}
.result_box.red{
   background: var(--color-red-20);
}

/* Progress bar container */
.progress-bar-container {
   width: 100%;
   background-color: #f3f3f3;
   border-radius: 25px;
   margin: 20px 0;
   padding: 5px;
}


/* Progress bar fill */
/* .progress-bar {
   height: 30px;
   background-color: #4caf50; 
   width: 0;
   border-radius: 20px;
   text-align: center;
   line-height: 30px;
   color: white;
   transition: width 0.5s;
} */

/* Progress bar container */
.progress-bar-container {
   width: 100%;
   background-color: #f3f3f3;
   border-radius: 25px;
   margin: 20px 0;
   padding: 5px;
}

/* Progress bar fill */
.progress-bar {
   height: 30px;
   width: 0;
   border-radius: 20px;
   text-align: center;
   line-height: 30px;
   color: white;
   transition: width 0.5s;
}

/* Green progress bar */
.progress-bar.green {
   background-color: #4caf50; 
}

/* Red progress bar */
.progress-bar.red {
   background-color: red;
}

/* Styles for correct and incorrect answers */
.correct {
   background-color: #d4edda; /* Light green background for correct answer */
   color: #155724; /* Dark green text for correct answer */
   border: 1px solid #c3e6cb; /* Border color for correct answer */
   padding: 5px;
   border-radius: 4px;
}

.incorrect {
   background-color: #f8d7da; /* Light red background for incorrect answer */
   color: #721c24; /* Dark red text for incorrect answer */
   border: 1px solid #f5c6cb; /* Border color for incorrect answer */
   padding: 5px;
   border-radius: 4px;
}

/* Optionally, styles for the default state */
.answers ul li {
   list-style: none;
   margin-bottom: 10px;
}

.score{
   color :var(--color-grey-100);
}
.score span{
   /* color :#4caf50; */
   font-weight: 900;
   font-size: 1.8rem;
}

.score.green{
   color: #4caf50;
}
.score.red{
   color: red;
}
.result_message{
   margin-top: 1rem;
   color :var(--color-grey-400);
   border-bottom: 1px solid #4caf50;
   padding: 2rem 0;
}

.result_message.red{
   border-bottom: 1px solid red;
}

.result_message.green{
   border-bottom: 1px solid #4caf50;
}

.btn_control.red{
   border: 1px solid red;
   color:#fff;
   background: red;
   
}

.btn_control.green{
   border: 1px solid #4caf50;
   color:#fff;
   background: #4caf50;
   
}

.empty_questions {
   min-height: 20vh;
   background-color: var(--color-red-100);
   margin: 4rem 10rem;
   text-align: center;
   padding: 4rem;
}

.empty_questions  button{
   background: var(--primary-color);
   color: #fff;
   margin-top: 1rem;
}

@media(max-width : 1200px){
   .quiz {
      padding: 4rem 8rem;
  }
}

@media(max-width : 900px){
   .quiz {
      padding: 4rem 4rem;
  }
  .result_box {
   margin: 4rem 15rem;
  
}
}

@media(max-width : 700px){
   
  .result_box {
   margin: 4rem 10rem;
  
}
.quiz_header {
 
   padding: 4rem 8rem;
}
}

@media(max-width : 530px){
   .quiz {
      padding: 4rem 2rem;
  }
   .result_box {
    margin: 4rem 5rem;
   
 }
 .quiz_header {
 
   padding: 4rem 5rem;
}
.empty_questions {
 
   margin: 4rem 2rem;
}
 }

 @media(max-width : 414px){
   .btn_control.green {
 font-size: 1rem;
 }
 .questions h2 {
   font-size: 2rem;
}
.questions h4 {
   font-size: 1.3rem;
}
.progress-bar {
   height: 13px;
   line-height: 13px;
}
}

@media(max-width : 350px){
   .result_box {
      margin: 4rem 2rem;
  }
 
}