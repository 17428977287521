.video_component{
   /* width: 100%; */
}
.video_resource{
   /* display: flex;
   justify-content: start; */
   padding: 2rem 4rem ;
 
}
.single_video_resource{
   padding: 2rem 4rem;
   
}
.single_resource_controls .single_controls{
 display: flex;
 justify-content: flex-start;

}

.single_controls span{
   margin-right: 1rem;
   font-size: 1.3rem; 
   color:var(--primary-color);
   border-bottom: 1px solid var(--primary-color);
   padding: .9rem 0;
   margin-bottom: 2rem;
}
.single_resource_content{

   /* background: red; */
   height: 50vh;
  
}



.tabs-container {
   margin: 20px;
 }
 
 .tabs {
   display: flex;
   border-bottom: 1px solid #ccc;
   margin-bottom: 10px;
 }
 
 .tab-button {
   flex: 1;
   padding: 10px;
   cursor: pointer;
   background-color: #fff;
   border: none;
   outline: none;
   transition: background-color 0.3s ease;
 }
 
 .tab-button.active {
   /* background-color: var(--primary-color); */
   /* color: white; */
   color: var(--primary-color);
   border-bottom: 1px solid  var(--primary-color);;
 }
 
 .tab-content {
   padding: 20px;
   /* background-color: #f9f9f9; */
   /* border: 1px solid #ccc; */
 }

 .fileBox{
   background: var(--color-red-150);
   padding:1.5rem;
   margin: 1rem ;
   width:25%;
   height:20%;
   border-radius: 1rem;

}
.fileName i {
   margin-right: 3px;
   font-size: 1rem;
   color: var(--primary-color);
   font-size: 2rem;
}
.fileName p {
   font-size: 1.4rem;
}
.mb_size{
   margin-left: 2rem;
}
.download_icon i {
   font-size: 2rem;
}

 @media(max-width : 380px){
   .single_video_resource{
      padding: 0;
      
   }
   .fileBox{
      width: 100%;
   }

 }
 