.leaderboard{
   padding: 2rem ;
}
.leaderboardBox{
   padding: 0 10rem;
}
.leaderboard_header{
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.top_student_box{
   height: 15rem;
   width: 30rem;
   background: var( --color-red-150);
   color:#000;
   font-weight: 900;
}

.top_student_header {
   height: 50%;
   width: 100%;
   background: var(--primary-color); 
   display: flex;
   justify-content: flex-end;
}
.student_data{
   text-align: center;
   display: flex;
   align-items: center;
   padding: 1rem  2rem ;
}
.student_data img {
    width :4rem;
    margin-right: 1rem;
    
}

.leaderboard_body{
   margin-top: 4rem;
}

.leaderboard_result {
   margin-bottom: 4rem; 
 
}


.leaderboard_result .name span{
   margin-right: 2rem;
}
.details {
   display: flex;
   justify-content: space-between; 
   align-items: center; 
   border: 1px solid gold ; 
   /* #4caf50;  */
   border-radius: 4px; 
   padding: 1.7rem 8rem;
   margin: 0 auto;
   background-color: #FFF5F5;
   border-radius: 8px;
   /* border-radius: 8px; */
   position: relative;
   clip-path: polygon(2% 0%, 100% 0%, 98% 100%, 0% 100%);
   transition: all 0.3s ease;
   box-shadow: 0 0 0 2px gold;
}


.details h4 {
   flex: 1;
   margin: 0; 
   /* text-align: center;  */
}


.details .name {
   flex: 2; 
   font-weight: bold;
}

.details .points {
   text-align: right; 
}

.leaderboard_result .name{
   font-size: 1.7rem;
}
.details .points{
   font-size: 2rem;
   color: gold;
}
.buttons .active{
   background: var(--primary-color);
   color: #fff;
}
.buttons button{
   border-radius: 2rem;
}


.leaderboard_result .name {
   font-size: 1.3rem;
}

@media(max-width : 912px){
  
}
 
  @media(max-width : 540px){

   .leaderboard{
      padding: 0 0rem ;
   }
 }
 
 
 @media(max-width : 380px){
   .leaderboard{
      padding: 0 0rem ;
   }
 .leaderboard_top_header{
   flex-direction: column;
 }
 .toggle_dureation{
   margin-top: 2rem;
 }
 .toggle_dureation .buttons button{
   width: 14rem;
   padding: 0.5rem;
 }
 .leaderboard {
   margin-top: 3rem;
}
.details {

   padding: 1.7rem 1.3rem;
}
.details .points {
   font-size: 1.2rem;
}
.leaderboard_result .name {
   font-size: 1rem;
}
.top_student_box {
   height: 12rem;
}
 
 }