.avatar {
   width: 50px; 
   height: 50px;
   background-color: var(--color-red-200); 
   color: #fff; 
   border-radius: 50%; 
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 20px; 
   font-weight: bold; 
 }
 