/* .login_img {
   background-image: url('../assets/logo_img.png');
   background-size: 'cover';
   background-position: 'center';
   background-repeat: 'no-repeat';
   width: '500px'; 
   height: '100%';
   min-width: 50vw;
  margin-left: 2rem;
  border-radius: 5rem;
 }

 @media(max-width : 760px){

   .login_img {
      background-image: url('../assets/logo_login.png');
      background-repeat: no-repeat; 
      background-position: center;
 
    }
} */

/* Main wrapper */
.loginWrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   min-height: 100vh;
   padding: 20px;
 }
 
 /* Header */
 .loginHeader {
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 20px;
 }
 
 /* Login box */
 .loginBox {
   display: flex;
   flex-direction: column; 
   align-items: center;    
   justify-content: center;
   /* max-width: 500px; */
   width: 100%;
   padding: 20px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   border-radius: 8px;
   background-color: #fff;
 }
 
 /* Login section */
 .loginSection {
   width: 100%;
   margin-bottom: 20px; /* Space between form and image */
 }
 
 /* Image container */
 .imageContainer {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
 }
 
 /* Login images */
 .loginImg {
   width: 45vw;
    height: 85vh;
    margin-left: 2rem;
 
   display: none; 
 }
 
 /* Show on desktop */
 .desktopImage {
   display: block;
 }
 
 .mobileImage {
   display: none;
 }
 
 /* Media query for mobile devices */
 @media (max-width: 768px) {
   /* Hide desktop image on mobile */
   .desktopImage {
     display: none;
   }
 
   /* Show mobile image on mobile */
   .mobileImage {
     display: block;
   }
 
   .loginBox {
     width: 90%;
   }
   .loginImg {
     
      height: 40rem;
      width: 100%;
       
    }
    .login-header .logo img {
      max-width: 30vw;
  }
  
 }

 @media(max-width : 350px){
   .loginImg {
     
      height: 30rem;
    
       
    }
 }
 