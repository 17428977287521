.modal-overlay {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 1000; /* Ensure the modal is on top */
 }
 
 .modal-content {
   background: white;
   padding: 20px;
   border-radius: 8px;
   position: relative;
   width: 100%; /* Width of modal */
   max-width: 90%; 
   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
 }
 
 .modal-close {
   position: absolute;
   top: 10px;
   right: 10px;
   background: none;
   border: none;
   font-size: 1.5rem;
   cursor: pointer;
 }
 .modal_buttons{
  margin-top: 5rem;
 }
 .modal_buttons a{
  background: var(--primary-color);
  color:#fff;
  padding: 1.2rem;
 }

 /* @media(max-width : 380px){
  .modal-content {
   width: 100%;
  }
 } */