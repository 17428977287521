.single-video-player {
 
   width: 100%;
 
 }
 .single-video-player video ,  iframe{
   width: 100%;
   height: 50vh;

 }

 /* .single-video-player video {
  height: 50% !important;
} */


.single-video-player  video ,.single-video-player  iframe{
  object-fit: initial;
  border-radius: 0 !important; 
  height: 90% !important;
}

 
 .single-controls {
   margin-top: 10px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 2rem;
   padding: 2rem 4rem;
  
 }
 .single-description{
   padding: 2rem 4rem;
 }
 .single-control{
   color: var(--color-green-100);
   font-size: 1rem;
   display: flex;
   align-items: center;
 }

 .single-control span{
  
   display: flex;
   align-items: center;
 }
 .single-control i{
   font-size: 2rem;
 }
.single-topic_n_points span {
   margin-right: 1rem;
}
 .single-topic_n_points .single-duration , .single-topic_n_points .single-points{
   background: var( --color-red-100);
   padding: .7rem;
   border-radius: 1rem;
   color: var(--primary-color);
 }
 
 button {
   /* padding: 10px 20px;
   font-size: 16px;
   cursor: pointer; */
 }

 .single-description{
  margin-top: 2rem;

 }

 .single-description h3{
  font-weight: 400;
 }
 .modal_box h3{
  margin-top: 2rem;
  font-size: 2rem;
 }
 .modal_box p {
  margin-top: 1rem;
 }

