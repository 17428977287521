.video-player {
   display: flex;
   flex-direction: column; 
   width: 25vw;
 
 
 }
 .video-container {
  /* height: 100%; */
}
/* Ensure these styles do not apply to ReactPlayer */
 video {
  object-fit: initial; 
  /* overflow: visible;   */
  border-radius: 1rem;
}

 .video-player video , .video-player iframe{
   width: 25vw;
   border-radius: 1rem;
   height: 100%;

 }
 
 .controls {
   margin-top: 10px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 2rem;
  
 }
 .control{
   color: var(--color-green-100);
   font-size: 1rem;
   display: flex;
   align-items: center;
 }

 .control span{
  
   display: flex;
   align-items: center;
 }
 .control i{
   font-size: 2rem;
 }
.topic_n_points span {
   margin-right: 1rem;
}
 .topic_n_points .duration , .topic_n_points .points{
   background: var( --color-red-100);
   padding: .7rem;
   border-radius: 1rem;
   color: var(--primary-color);
 }
 
 button {
   padding: 10px 20px;
   font-size: 16px;
   cursor: pointer;
 }

 .description{
  margin-top: 2rem;

 }

 .description h3{
  font-weight: 400;
  

 }

 @media(max-width:810px){
  .video-player video , .video-player iframe{
    /* height: 100%; */
  }
  
 }

 @media(max-width : 768px){


}

@media(max-width : 690px){
  .video-player video ,.video-player iframe{
    width: 100%;
  }
  .video-player {
     width: 100%;
     margin-bottom: 3rem;
  }
  
}

@media(max-width : 380px){
 
}


.player-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.custom-play-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  cursor: pointer;
}

.play-icon {
  font-size: 50px;
  color: white;
}

.custom-play-overlay span {
  color: white;
  margin-left: 10px;
}
