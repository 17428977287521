
.profile_container{
   padding: 2rem 10rem;
}
.profile_box{
   border: 1px solid var(--color-grey-100);
   width: 100%;
   padding: 2rem;
   min-height: 15rem;
   border-radius: 2rem;
   margin: 2rem 0;
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.profileinformation_box{
   border: 1px solid var(--color-grey-100);
   width: 100%;
   padding: 2rem;
   min-height: 15rem;
   border-radius: 2rem;
   margin: 2rem 0;
   display: flex;
   flex-direction: column;
   
}
.profileinformation_box h2{
   margin :1rem 0;
}
.profileInformation{
   display: flex;
   flex-direction: column;
}
.profileItem{
   display:flex;
   margin-bottom: 2rem;
}
.profileName h3{
   font-weight: 400;
}
.profileName span{
   font-weight: 200;
}

.profile{
   display: flex;
   align-items: center;

   
}
.profileInformation{
   display: flex;
}

.profileName{
   margin-right: 3rem;
   
}

.profile img{
 margin-right: 2rem;
}
.editProfile a{
   padding: 1rem;
   background: var( --color-grey-200);
   color:#000;
   border-radius: 1rem;
}
@media(max-width : 1200px){
   .profile_container {
      padding: 2rem 5rem;
  }
}
@media(max-width : 460px){
   .profile_container {
      padding: 2rem 2rem;
  }

}

@media(max-width : 310px){
   .profile img {
      margin-right: 1rem;
  }
}