.profileImgBox{
   padding: 4rem 25rem ;
   margin-bottom: 2rem;
}
.profile_imagebox{
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 2rem;
}
.profile_imagebox button:nth-child(1){
   margin-right: 2rem;
}
.profile_form_field input  , .profile_form_field  select{
   background: var(--color-grey-200);
   padding: 1rem;
   display: flex;
   width: 30vw;
}
.profile_form {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   margin-bottom: 2rem;

}
.profile_upload_buttons button {
   padding: 1rem;
   font-size: 1.3rem;
   margin-right: 1rem;
}
.updateButton button {
   background: var(--primary-color);
   color: #fff;
   border-radius: 1rem;
}
.profile_img img{
   width: 9vw;
   height: 13vh;
   border-radius: 50%;
  
}
.profile_form_field  input , .profile_form_field  select{
   margin-top: 1rem;
}
.profileNameBox{
   margin-left: 1rem;
}
@media(max-width : 1300px){
   .profileImgBox {
      padding: 4rem 15rem;
      
   }
   .profile_form_field input, .profile_form_field select {
      width: 36vw;
  }

 }

 @media(max-width : 1100px){
   .profile_img img {
      width: 11vw;
      height: 13vh;

  }
 }

 @media(max-width : 938px){
   .profile_form_field input, .profile_form_field select {
      width: 100%;
  }
  .profile_form {
   display: block;

  }
  .profileImgBox {
   padding: 4rem 10rem;
}
  .profile_form_field{
   margin-bottom: 2rem;
  }
 }

 @media(max-width : 730px){
   .profileImgBox {
      padding: 4rem 5rem;
  }
  .profile_upload_buttons button{
   padding: 1rem;
   font-size: 1.3rem;

  }
 
  .profile_img img {
   width: 18vw;

}
 }

 @media(max-width : 484px){
   .profileImgBox {
      padding: 4rem 3rem;
  }
  
 }

 @media(max-width :380px){
   .profile_imagebox {
      flex-direction: column;
  }
  .profile_img img {
   margin-bottom: 2rem;
   width: 30vw;
}

 }

